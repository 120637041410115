import { store } from "@/store/store";
import { eventBus } from "@/eventBus";
import { mapMutations } from "vuex";

const Redirection = {
  methods: {
    ...mapMutations(["setFirebaseUserUID", "setRegActiveDeeplink", "setContentData"]),
    redirectToLocator(content) {
      if(this.locatorData(content)) {
        window.open(this.locatorData(content), '_blank', 'noopener,noreferrer');
      }else {
        alert('sorry..! content is not accessible.')
      }
    }, 

    locatorData(content) {
      let locaterData = content?.contentdetails?.filter((item, i) =>{
        return item.devicetype === "web"
      });
      let locater = locaterData && locaterData[0]?.locator;
      if(locater && content?.contentprovider == "OTT_PLAY") {
          let token = localStorage.getItem("sessionToken");
          let source = "KCCL";
          let backUrl = encodeURIComponent(window.location.href);
          locater = `https://www.ottplay.com/auth?seoUrl=${locater}&token=${token}&backUrl=${backUrl}&source=${source}`;
      }
      return locater;
    },
    redirectToLogin() {
        let payload = {
            state: true,
            formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
    },

    redirectToPlans() {
    this.setRegActiveDeeplink(true);
    localStorage.setItem("paymentGetwaySaveUrl", location.href);
    let currentLanguage = this.getCurrentLanguageUrlBase();
    this.$router.push({name: 'seeAllPlans', params: {lang: currentLanguage}});
    },

    redirectToDetailPage(item) {
      this.setContentData(item);
      console.log('redirectToDetailPage', item)
      let category = 'movies';
      if (item?.category?.toLowerCase() == 'tvshow') {
          category = 'tvshows';
      } else if (item?.category?.toLowerCase() == 'movie') {
          category ='movies';
      } else if (item?.category?.toLowerCase() == 'tvchanel') {
          category = 'livetv';
      } else if (item?.objecttype?.toLowerCase() == 'season') {
          category = 'tvshows';
      } 
      this.$router.push({
          name: 'detailPageEarth', params: {
          category: category,
          title: item.title.replaceAll(" ", "-").toLowerCase(),
          contentId: item.objecttype == "SEASON" ? item.seriesid : item.objectid
          }
      });
    },
    
    getCurrentLanguageUrlBase: function() {
    let lang = localStorage.getItem("setDisplayLanguageCode");
    let defaultLanguage = window.PROVIDER_SETUP_CONFIG.defaultLanguage;
    let supportedLanguages = window.PROVIDER_SETUP_CONFIG.supportLanguageList;

    if (lang === defaultLanguage) {
        return null;
    }
    for(let index = 0; index < supportedLanguages.length; index ++){
        let languageItem = supportedLanguages[index];
        if(lang === languageItem){
        return languageItem;
        }
    }
    return 'en';
    },
  }
};

export default Redirection;